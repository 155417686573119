import React, { Component } from 'react'

//Componente que muestra los clientes que tiene una empresa
class ClientsListTotal extends Component {
  async componentDidMount(){
    let init = this.props.listInit
    let end = init +10
    await this.displayClients(init,end)
  }

  async componentDidUpdate() {
    let init = this.props.clientsListInit
    let end = init +10
    
    if (this.state.pagesClients ===""){

      await this.displayClients(init,end)
    }
  }

  // async editEmployee(id){
  //   try{
  //     let employee
  //     if (this.props.myEmployee){
  //       if (this.props.myEmployee.id!==id){
  //         employee = await this.props.search(id,0,0,{},false,"", false, false)
  //       }
  //     }else{
  //       employee = await this.props.search(id,0,0,{},false,"", false, false)
  //     }
      
  //     if (employee || this.props.myEmployee){
  //       await this.props.showCreationFields()
  //     }
  //   }catch(err){
  //     alert("error al intentar crear un empleado")
  //   }
  // }

  async formatDate(date, showTime) {
    try {
      let d = new Date(date);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      if (day.length < 2) {
        day = "0" + day;
      }
      //let year = d.getFullYear();
      let hours = d.getHours().toString();
      if (hours.length < 2) {
        hours = "0" + hours;
      }
      let minutes = d.getMinutes().toString();
      if (minutes.length < 2) {
        minutes = "0" + minutes;
      }
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      let dateToShow;
      if (showTime) {
        dateToShow = day + "-" + month + " " + hours + ":" + minutes;
      } else {
        dateToShow = day + "-" + month;
      }
      return dateToShow;
    } catch (err) {
      alert("Error al formatear la fecha");
    }
  }

  async displayClients(init, end) {

    try {
      let clientsList = await this.props.clientsTotal;
      
     

      let pagesClients = Math.ceil(clientsList.length / 10);
      //let pageClient = this.state.pageClient > pagesClients ? pagesClients : this.state.pageClient;
      // let initClients = (pageClient - 1) * 10;
      // let endClients = pageClient * 10;
      let pageClient = Math.ceil(end/10)

      let clientsToShow = await clientsList.slice(init, end);
      for (let i = 0; i < clientsToShow.length; i++) {
        
        clientsToShow[i]["co2Format"]= Math.round(clientsToShow[i]["total_co2"]/100000,2) + "  kg co2";
                      
      }
     


      this.setState({
        pagesClients: pagesClients,
        pageClient: pageClient,
        clientsToShow: clientsToShow,
      });

      this.props.editClientsListInit(init);
      return pagesClients;
    // } catch (err) {
    //   alert("Error al mostrar los viajes");

    // }


    // this.setState({ pageClient: pageClient });
    // this.setState({ clientsToShow: clientsToShow });
    // this.props.editClientsListInit(init);
    // return pagesClients;
    } catch (err) {
      alert("Error al mostrar los viajes")
    }
  }
    
  constructor(props) {
    super(props)
    this.state = {
      clients: [],
      pagesClients: "",
      pageClient: 1,
      numReloads: 0,
      clientsToShow: [],
    };
    this.displayClients= this.displayClients.bind(this);
  }

  render() {
     try{
      var createClient = (
        <form>
            <table>
            <tr className='w-100'>
            <td className='w-17'>
            <input
                    ref={(emailCreate) => {
                      this.emailCreate = emailCreate;
                    }}
                    className="form-control form-control-lg"
                    defaultValue=""
                    placeholder="Email"
                    //enable it only if this.state.clientsToShow[key]["address"] is null
                />
              </td>

            <td className='w-17'>
              <input
                    ref={(usernameCreate) => {
                      this.usernameCreate = usernameCreate;
                    }}
                    className="form-control form-control-lg"
                    defaultValue=""
                    placeholder="Nombre"
                    //enable it only if this.state.clientsToShow[key]["address"] is null
                />
            </td>
            <td className='w-17'>
              <input
                  ref={(addressCreate) => {
                    this.addressCreate = addressCreate;
                  }}
                  className="form-control form-control-lg"
                  defaultValue=""
                  placeholder="Dirección"
                  //enable it only if this.state.clientsToShow[key]["address"] is null
                
                />
              </td>
            <td>
            </td>

            <td className='w-5 text-right h-50'>  </td>
          
            <td className='w-5 text-right h-50'>  {
                    {}
                      ? <button
                        className="btn btn-outline-secondary fs-10 h-100"
                        
                        onClick={(event) => {

                  event.preventDefault()
                  this.props.actualizarEstadoYAlmacenar()
                          this.props.editClient(this.emailCreate.value, this.addressCreate.value, this.usernameCreate.value);
                        }}
                      >
                        Crear
                      </button>
                      : null
                  }</td>
                      <td className='w-5 text-right h-50'>  {
                        
                      }</td>
          

          </tr>
          </table>

        </form>
         )
      var hayClientes = <div></div>
      if(this.state.clientsToShow.length === 0){
        hayClientes = (
          
          <div id= "content" className="mt-3">
            <br></br>
            <p className="te-ali-cen">   Todavía no has creado ningún cliente</p>
          </div>
          
        )
      }

      if (this.state.pagesClients > 1 && this.state.pageClient !== "") {
        var page = this.state.pageClient
        var pages = this.state.pagesClients
        var init = (page-1)*10
        var end = page*10
        var paginatorClients = 
          <div className="wid-100 te-ali-cen">
            <div> Página {page} de {pages} </div>
            <table className="wid-100">
              <tbody className="wid-100">
                <tr className="wid-100">
                  <td className="wid-25"></td>
                  <td className="wid-25">
                    <button onClick={(event) => {
                      event.preventDefault()
                      if(page>1){
                        this.setState({page: page-1})
                        init = init - 10
                        end = end - 10
                      }
                      this.displayClients(init,end)
                      this.props.actualizarEstadoYAlmacenar()
                    }} 
                    className="btn btn-outline-secondary">Anterior </button>
                  </td>
                  <td className="wid-25">
                    <button onClick={(event) => {
                      event.preventDefault()
                      if(page<pages){
                        this.setState({page: page+1})
                        init = init + 10
                        end = end + 10
                      }
                      this.displayClients(init,end)
                      this.props.actualizarEstadoYAlmacenar()
                    }}
                    className="btn btn-outline-secondary">Siguiente </button>
                  </td>
                  <td className="wid-25"></td>
                </tr>
              </tbody>
            </table>
          </div>
      }else{
        <div></div>
      }

      var displayList =

        <div id="content" className="mt-3">
          <div className="card mb-4">
            {paginatorClients}
            {hayClientes}
            
            <div className="card-body">

            {createClient}

              <table className="table fs-12">

                <thead>
                  <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Dirección</th>
                    <th scope="col">CO2 total</th>
             
                  </tr>
                </thead>
                <tbody id="productList">

                
                

                  {this.state.clientsToShow.map((client, key) => {
                    var statusText = "";
                    var statusStyle = "green";

                    return (
                      <tr key={key} className='w-100'>
                        <td className='w-17'>{this.state.clientsToShow[key]["email"]}</td>

                        <td className='w-17'>{ this.state.clientsToShow[key]["username"]}
                          {/* <input
                                ref={(username) => {
                                  this.username = username;
                                }}
                                className="form-control form-control-lg"
                                defaultValue={ this.state.clientsToShow[key]["username"]}
                                //enable it only if this.state.clientsToShow[key]["address"] is null
                                disabled = {this.state.clientsToShow[key]["username"] !== null}
                            /> */}
                        </td>
                        <td className='w-17'>{this.state.clientsToShow[key]["address"]}
                          {/* <input
                              ref={(address) => {
                                this.address = address;
                              }}
                              className="form-control form-control-lg"
                              defaultValue={this.state.clientsToShow[key]["address"]}
                              //enable it only if this.state.clientsToShow[key]["address"] is null
                              disabled = {this.state.clientsToShow[key]["address"] !== null}
                            /> */}
                          </td>

                        {this.state.clientsToShow[key]["co2Format"]}
                        
                        <td className='w-5 text-right h-50 '> {} 
                          
                              </td>
                        
                        <td className='w-5 text-right h-50 '> {} 
                          
                              </td>
                                  <td className='text-right h-50 d-flex justify-content-center'>  {
                                    {}
                                      ? <button
                                        className="btn btn-outline-secondary fs-10 h-100"
                                      
                                        onClick={(event) => {
                                          
                                          this.props.tripsAndClients(true, false, this.state.clientsToShow[key]["trips_ids"]);
                                        }}
                                      >
                                        Ver viajes
                                      </button>
                                      : null
                                  }</td>
                       

                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        
      
       // check if its desktop
       if(this.props.device === 'desktop'){


        //Comprueba si debe crear varias páginas o no
        
  
        //Si la empresa no tiene empleados asignados
        

        


        
  
        //Creamos el box con la lista de empleados y unos títulos descriptivos
        return (
          <div id= "content" className="mt-3">
            <form className="mb-3"  onSubmit={(event) => {
              event.preventDefault()
              this.props.search(empl.id,0,0,{},false,"", false, false);
            }}></form>
            {displayList}
            {paginator}
          </div>
        )
        
      } else {
        if (this.state.pagesClients > 1 && this.state.page !== "") {
          var page = this.state.pageClient
          var pages = this.state.pagesClients
          var init = (page-1)*10
          var end = page*10
          var paginatorClients = 
            <div className="wid-100 te-ali-cen">
              <div> Página {page} de {pages} </div>
              <table className="wid-100">
                <tbody className="wid-100">
                  <tr className="wid-100">
                    <td className="wid-25"></td>
                    <td className="wid-25">
                      <button onClick={(event) => {
                        event.preventDefault()
                        if(page>1){
                          this.setState({page: page-1})
                          init = init - 10
                          end = end - 10
                        }
                        this.displayClients(init,end)
                        this.props.actualizarEstadoYAlmacenar()
                      }}
                      className="btn btn-outline-secondary">Anterior </button>
                    </td>
                    <td className="wid-25">
                      <button onClick={(event) => {
                        event.preventDefault()
                        if(page<pages){
                          this.setState({page: page+1})
                          init = init + 10
                          end = end + 10
                        }
                        this.displayClients(init,end)
                        this.props.actualizarEstadoYAlmacenar()
                      }}
                      className="btn btn-outline-secondary">Siguiente </button>
                    </td>
                    <td className="wid-25"></td>
                  </tr>
                </tbody>
              </table>
            </div>
        }else{
          <div></div>
        }
  
        

  
        return (
          <div id= "content" className="mt-3">
                    {displayList}
          </div>
        )
      }
    }catch(err){
      return(<div>Error al cargar la lista de viajes</div>)
    }
  }
}

export default ClientsListTotal;