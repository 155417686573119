import React from "react";
import { useContext, useState, useEffect } from "react";
import reactGA from "react-ga";
import { UserContext } from "../context/UserContext";
import ErrorMessage from "./ErrorMessage";
import CustomSpinner from "./CustomSpinner";
import { Router, Link } from "react-router-dom";


//Componente que se encarga de la funcionalidad "Crear un usuario"
const RegisterModal = (props) => {
    useEffect(() => {
        reactGA.pageview(window.location.pathname);
    }, []);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [, setToken] = useContext(UserContext);
    const checkBox = document.querySelector("#cbox1");
    const [loader, setLoader] = useState(<div></div>);




    // Se crea la encriptación con salt
    const CryptoJS = require("crypto-js");
    const salt = "farm1990M0O";
    const salt1 = "f1nd1ngn3m0";
    const hashPassword = CryptoJS.SHA256(salt1 + password + salt).toString();




    //Guardar al nuevo usuario en la base de datos
    const submitRegistration = async () => {

        const fetchWithTimeout = async( resource, options = {}, timeout = 3000) =>{
            const controller = new AbortController();
            const id = setTimeout(() => controller.abort(), timeout);
            
            
            try {
              const response = await fetch(resource, {
                ...options,
                signal: controller.signal,
              });
              return response;
            } finally {
              // Clear the timeout so it doesn’t trigger if the response arrives
              console.log("clear")
              clearTimeout(id);
            }
          }
        

        //ENDPOINTS

        //let endpointlocal = "http://api.ecargo.link/api/users"
        let endpointlocal = "http://localhost:8000/api/users"
        let endpoint2 = "https://api.ecargo.link/api/users"
        let endpoint = "https://api.traxain.xyz/api/users"
        //let endpoint = "https://api.ecargo.link/api/users"



        let requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            //Se cambia la palabra password por hashPassword
            body: JSON.stringify({ email: email, hashed_password: hashPassword }),

        };
        var response
        setLoader(<CustomSpinner/>)





        try{
            response = await fetchWithTimeout(endpointlocal, requestOptions, 500)
            if (!response.ok) {
                throw new Error('Request failed with status ' + response.status);
            }else{
                setLoader(<div></div>)
                // //console.log("Usuario registrado en endpointlocal")
            }
            
        } catch (err) {

            // //console.log("Register: Fallo en endpointlocal");
            try{
                response = await fetchWithTimeout(endpoint, requestOptions, 3000)
                if (!response.ok) {
                    throw new Error('Request failed with status ' + response.status);
                }else{
                    setLoader(<div></div>)
                    // //console.log("Usuario registrado en endpoint")
                }
                }catch (err){
                // //console.log("Register: Fallo en endpoint");
                try{
                    response = await fetchWithTimeout(endpoint2, requestOptions, 3000)  
                    if (!response.ok) {
                        throw new Error('Request failed with status ' + response.status);
                    }else{
                        setLoader(<div></div>)
                        // //console.log("Usuario registrado en endpoint2")
                    }
        
                }catch (err){
                    if (response && response.status === 401) {
                        setLoader(<div></div>)
                        setErrorMessage("El correo que intenta registrar ya existe");
                        return;
                    } else {
                        // Otro tipo de error
                        // //console.log("Otro tipo de error");
                        console.error('Error:', err);
                    }
                }
            }
        }
    

        const data = await response.json();
        if (!response.ok) {
            setErrorMessage(data.detail);
        } else {
            setToken(data.access_token);
        }

    }


    //Hacemos comprobaciones para que la contraseña establecida sea válida y la ploítica de privacidad aceptada
    const handleSubmit = (event) => {
        event.preventDefault();
        if (checkBox.checked) {
            if (password === confirmationPassword && password.length > 7) {
                reactGA.event({
                    action: "submit reg",
                    label: "test label"
                })
                submitRegistration();
                let estadoUsuario = localStorage.getItem("TraxainUserToken");
                comprobarEstado();

                function comprobarEstado() {
                    if (estadoUsuario !== "null") {
                        if (Math.random()>0.5){
                            setTimeout(() => {
                              window.location.href = 'https://drive-team.es';
                            }, 2)
                          }else{
                            setTimeout(() => {
                              window.location.href = 'https://www.drive-team.es';
                            }, 2)
                    
                          }
                    }
                    else {
                        estadoUsuario = localStorage.getItem("TraxainUserToken");
                        setTimeout(() => comprobarEstado(), 50);
                    }
                }
            } else {
                setErrorMessage("Las contraseñas deben coincidir y tener al menos 8 caracteres");
            }
        } else {
            setErrorMessage("Debe aceptar la política de privacidad");
        }
    }

    var show = true
    var showHideClassName = show ? "modal display-block" : "modal display-none";


    //Aspecto visual del box de crear usuario
    return (
        <div className='showHideClassName text-center'>
            <section className="modal-main text-center b-rad-10-px b-1-s bg-blanco">
                <div className="column">
                    <form className="box" onSubmit={handleSubmit}>
                        <h1 className="title has-text-centered">Crea un usuario</h1>
                        <div className="field">
                            <label className="label">Email</label>
                            <div className="control">
                                <input
                                    type="email"
                                    placeholder="Enter Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="input"
                                    required
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Contraseña</label>
                            <div className="control">
                                <input
                                    type="password"
                                    placeholder="Enter Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="input"
                                    required
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Confirma tu contraseña</label>
                            <div className="control">
                                <input
                                    type="password"
                                    placeholder="Enter Password"
                                    value={confirmationPassword}
                                    onChange={(e) => setConfirmationPassword(e.target.value)}
                                    className="input"
                                    required
                                />
                            </div>
                            <br></br>
                            <label>
                                <input type="checkbox" id="cbox1" value="first_checkbox"></input> Acepto la <a href="https://www.traxain.com/legal" target="_blank">Política de privacidad de Traxain</a></label><br></br>
                        </div>
                        <ErrorMessage message={errorMessage} />
                        <br/>
                        {loader}
                        <br/>
                        <button className="button is-primary bc-primary b-c-primary" type="submit">
                            Guardar
                        </button>
                    </form>
                </div>
                <Link to="/">
                <button type="button" class="btn w-50 mb-5-px b-1-s bg-blanco" onClick={props.onCloseReg}>
                    Volver atrás
                </button>
                </Link>
            </section>
        </div>

    );
}

export default RegisterModal;
